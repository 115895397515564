<template>
  <!-- TODO: Unit test DocInput! -->
  <div v-if="multiple" class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <div
      v-for="docId of context.value as string[]"
      :key="docId"
      class="w-full border border-gray-400 rounded-lg relative"
    >
      <Menu v-if="!!allDocs.has(docId)" as="template">
        <MenuButton as="template">
          <DocBox :doc="allDocs.get(docId)" class="cursor-pointer" :key="context.value as string" />
        </MenuButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="viewDoc(allDocs.get(docId))"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >View</a
                >
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  @click="chooseDoc"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >Replace</a
                >
              </MenuItem>
            </div>
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="
                    props.context.node.input(
                      (context.value! as string[]).filter((id) => id !== docId)
                    )
                  "
                  :class="[
                    active ? 'bg-gray-100 text-red-800' : 'text-red-600',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >Remove</a
                >
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <div v-else class="rounded-lg bg-white shadow h-24 flex items-center justify-center">
        <IonSpinner />
      </div>
    </div>

    <div
      @click="chooseDoc"
      class="relative cursor-pointer p-6 flex items-center justify-center rounded-lg text-center bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-2 border-dashed border-gray-300"
    >
      <div class="flex h-12 items-center justify-center -m-[1px]">
        <DocumentPlusIcon class="mr-2 h-8 w-8 text-gray-400" />
        <span class="block text-sm font-semibold text-gray-900"
          >Add or select document{{ multiple ? 's' : '' }}</span
        >
      </div>
    </div>
  </div>
  <div class="w-full" v-else>
    <div
      v-if="!context.value"
      @click="chooseDoc"
      class="relative cursor-pointer w-full p-6 flex items-center justify-center rounded-lg text-center bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-2 border-dashed border-gray-300"
    >
      <div class="flex h-12 items-center justify-center -m-[1px]">
        <DocumentPlusIcon class="mr-2 h-8 w-8 text-gray-400" />
        <span class="block text-sm font-semibold text-gray-900">Add or select document</span>
      </div>
    </div>
    <div
      class="w-full border border-gray-400 rounded-lg"
      v-else-if="typeof context.value === 'string'"
    >
      <Menu v-if="!!allDocs.has(context.value)" as="template">
        <MenuButton
          :key="context.value as string"
          @vue:mounted="checkDocType(allDocs.get(context.value)!)"
          as="template"
        >
          <DocBox :doc="allDocs.get(context.value)" class="cursor-pointer" />
        </MenuButton>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="viewDoc(allDocs.get(context.value))"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >View</a
                >
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  @click="chooseDoc"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >Replace</a
                >
              </MenuItem>
            </div>
            <div class="py-1">
              <MenuItem v-slot="{ active }">
                <a
                  @click="props.context.node.input(null)"
                  :class="[
                    active ? 'bg-gray-100 text-red-800' : 'text-red-600',
                    'block px-4 py-2 text-sm cursor-pointer'
                  ]"
                  >Remove</a
                >
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <div v-else class="rounded-lg bg-white shadow h-24 flex items-center justify-center">
        <IonSpinner />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import addDocuments from '@/helpers/addDocuments'
import { DocumentPlusIcon } from '@heroicons/vue/24/outline'
import { useModalStore } from '@/stores/modal'
import SelectDocModal from '@/components/SelectDocModal.vue'
import DocPreviewModal from '@/components/DocPreviewModal.vue'
import DocBox from '@/components/DocBox.vue'
import type { DocumentData } from '@/types/types'
import { createMessage, type FormKitNode } from '@formkit/core'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { IonSpinner } from '@ionic/vue'
import { useCertificateListQuery } from '@/queries/certificateList'
import { onMounted, watch } from 'vue'
import { useDocumentsQuery } from '@/queries/documents'
import { useUserProfileQuery } from '@/queries/userProfile'
import axios from 'axios'
import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'
import router from '@/router'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/stores/auth'

const props = defineProps<{
  context: {
    node: FormKitNode
    docType?: string
    value?: string | string[]
    label?: string
  }
}>()

const multiple = props.context.node.name.split('|')[0] === 'ANY'

const { userId } = storeToRefs(useAuthStore())

const { data: userDocs } = useDocumentsQuery()
const { data: userProfile } = useUserProfileQuery()

const { data: workspaceDocsByField } = useQuery({
  queryKey: ['workspaceDocsByField', props.context.node.name],
  enabled: computed(() => !!userId.value),
  queryFn: async () => {
    const { data } = await axios.get(
      `crewdentialsProfile/workspaceDocsByField/${props.context.node.name}`,
      {
        params: {
          inviteId: router.currentRoute.value.params.inviteId,
          workflowId: router.currentRoute.value.params.taskId
        }
      }
    )
    return data
  }
})

const allDocs = computed<Map<string, DocumentData>>(() => {
  const docsMap = new Map()
  if (!!workspaceDocsByField.value) {
    workspaceDocsByField.value.forEach((doc: DocumentData) => docsMap.set(doc.id, doc))
  }
  if (!!userDocs.value) {
    userDocs.value.forEach((doc: DocumentData) => {
      if (doc.status === 'COMPLETE') docsMap.set(doc.id, doc)
    })
  }
  return docsMap
})

const viewDoc = (doc: any) => {
  const docBelongsToWorkspace = !userDocs.value.find(({ id }: any) => id === doc.id)
  if (!userProfile.value?.acceptedTerms || docBelongsToWorkspace) {
    presentModal({ component: DocPreviewModal, componentProps: { doc } })
  } else {
    router.push(`../docs/${doc.id}`)
  }
}

props.context!.node.on('input', () => {
  if (!multiple) {
    toggleWarningMessage(false)
  }
})

const checkDocType = async (doc: DocumentData | null) => {
  toggleWarningMessage(false)
  if (
    !!doc &&
    props.context.docType !== 'CUSTOM' &&
    doc.categoryId !== props.context.docType &&
    doc.certificateTypeId !== props.context.docType
  )
    toggleWarningMessage(true)
}

props.context.node.props.validationMessages = {
  required: 'This field is required',
  require_one: 'At least one of these docs is required'
}

const { data: docs } = useDocumentsQuery()
const { data: certificateTypes } = useCertificateListQuery()

watch(
  certificateTypes,
  (value) => {
    const label = props.context.label
    const docType = props.context.docType
    if (!!value && !label && !!docType) {
      const name = value.get(docType)?.name
      if (!!name) props.context.label = name
    }
  },
  { immediate: true }
)

onMounted(() => {
  watch(
    docs,
    (value) => {
      if (!!value) {
        const currentValue = props.context.node.value
        if (!currentValue && !!props.context.docType) {
          const suggestedDoc = value.find(
            ({ certificateTypeId, status }: DocumentData) =>
              status === 'COMPLETE' && certificateTypeId === props.context.docType
          )
          if (!!suggestedDoc) {
            setValue([suggestedDoc.id] as any)
          }
        }
      }
    },
    { immediate: true }
  )
})

const { presentModal } = useModalStore()

const chooseDoc = async () => {
  if (!workspaceDocsByField.value || !userDocs.value) return

  const selectResult: { selectedDocs: DocumentData[]; newDocFiles: File[] } = await presentModal({
    component: SelectDocModal,
    componentProps: {
      title: props.context.label,
      preSelectedDocIds: !!props.context.value ? [props.context.value].flat() : [],
      documents: allDocs.value,
      docType: props.context.docType,
      multiple
    }
  })

  if (!!selectResult) {
    if (selectResult.selectedDocs) {
      const selectedDocIds = selectResult.selectedDocs.map(({ id }) => id)
      setValue(selectedDocIds)
    }
    if (selectResult.newDocFiles) {
      const finishedDocs: DocumentData[] = await addDocuments(selectResult.newDocFiles, false)

      if (!!finishedDocs) {
        const finishedDocIds = finishedDocs.map(({ id }) => id)
        if (!!multiple) {
          setValue(finishedDocIds, true)
        } else {
          const matchingDoc = finishedDocs.find(
            ({ certificateTypeId }) => props.context.docType === certificateTypeId
          )
          setValue([!!matchingDoc ? matchingDoc.id : finishedDocIds[0]])
        }
      }
    }
  }
}

const toggleWarningMessage = (show: boolean) => {
  if (show) {
    props.context!.node.store.set(
      createMessage({
        key: 'warning',
        blocking: false,
        value: show ? 'This doc may not be the correct type' : ''
      })
    )
  } else {
    props.context!.node.store.remove('warning')
  }
}

const setValue = (values: string[], merge?: boolean) => {
  props.context!.node.input(
    multiple ? (merge ? [...(props.context.value || []), ...values] : values) : values[0]
  )
}
</script>
